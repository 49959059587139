<template>
  <ClientOnly>
    <maps-yandex-maps-connector
      v-if="!stringIsNullOrWhitespace(fileName) && appConfig.VueSettingsPreRun.MapsProvider === 'yandex'"
      :kml-file-name="fileName"
    />
    <span
      v-else
      class="v-error-color"
      v-html="translate('mapsAddress.error')"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

defineProps<{
  fileName: string
}>()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const { stringIsNullOrWhitespace } = useCommon()
</script>
