<template>
  <div class="yandex-maps-connector">
    <div
      class="v-map-container"
      ref="connector"
    />
  </div>
</template>

<script setup lang="ts">
//TODO: rework as part of https://track.arora.pro/issue/ARORA-3690

const { kmlFileName } = defineProps<{
  kmlFileName: string
  center?: number[]
}>()

const connector = ref<HTMLDivElement>()
const cacheTime = 1000 * 60 * 15
const appConfig = useAppConfig()

// The handler for loading XML files.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onGeoXmlLoad(response: any, map: any): void {
  map.geoObjects.add(response.geoObjects)
  if (response.mapState) {
    response.mapState.applyToMap(map)
  } else {
    map.setBounds(response.geoObjects.getBounds())
  }
}

async function initializeYandexMap(): Promise<void> {
  const controls: string[] = []
  if (appConfig.VueSettingsPreRun.YaMapsShowSearchControl) controls.push('searchControl')
  if (appConfig.VueSettingsPreRun.YaMapsShowTypeSelector) controls.push('typeSelector')
  if (appConfig.VueSettingsPreRun.YaMapsShowZoomControl) controls.push('zoomControl')

  // @ts-expect-error no types for maps 2.1
  ymaps.ready(() => {
    // @ts-expect-error no types for maps 2.1
    const ymap = new ymaps.Map(
      connector.value,
      {
        center: [55.73, 37.75],
        zoom: appConfig.VueSettingsPreRun.YaMapsZoom,
        type: `yandex#${appConfig.VueSettingsPreRun.YaMapsType}`,
        controls: controls
      },
      {
        searchControlProvider: 'yandex#search',
        suppressMapOpenBlock: true
      }
    )

    if (!appConfig.VueSettingsPreRun.YaMapsEnableZoomOnScroll) ymap.behaviors.disable('scrollZoom')

    if (kmlFileName) {
      // @ts-expect-error no types for maps 2.1
      if (!window.ymaps) return
      // @ts-expect-error no types for maps 2.1
      if (!ymaps.GeoObjectCollection) return

      const now = new Date().getTime()
      const sign = kmlFileName.includes('?') ? '&' : '?'
      const digit = now - (now % cacheTime)

      // @ts-expect-error no types for maps 2.1
      ymaps.geoXml
        .load(kmlFileName + sign + digit)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => onGeoXmlLoad(response, ymap))
    }
  })
}

const yaMapsScriptKey: Readonly<string> = 'ymaps-script-id'

function CreateYandexMap(apiKey: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    // @ts-expect-error no types for maps 2.1

    if (window.ymaps && ymaps.GeoObjectCollection) return resolve(null)

    let scriptYandexMap = document.getElementById(yaMapsScriptKey)
    if (scriptYandexMap) {
      //got element by id but ymaps is not available yet
      scriptYandexMap.addEventListener('load', resolve)

      return
    }
    //convert document lang to Yandex Maps format
    let lang
    switch (document.documentElement.lang) {
      case 'uk': //Ukrainian
        lang = 'uk_UA'
        break
      case 'en': //English
        lang = 'en_US'
        break
      case 'tr': //Turkish
        lang = 'tr_TR'
        break
      default:
        lang = 'ru_RU'
        break
    }

    //add script to page
    scriptYandexMap = document.createElement('script')

    scriptYandexMap.setAttribute('id', yaMapsScriptKey)
    scriptYandexMap.setAttribute('src', `https://api-maps.yandex.ru/2.1/?apikey=${apiKey}&lang=${lang}`)

    document.head.appendChild(scriptYandexMap)

    scriptYandexMap.addEventListener('load', resolve)

    scriptYandexMap.onerror = reject
  })
}

onMounted(async () => {
  if (appConfig.RestaurantSettingsPreRun.YandexMapKey) {
    await CreateYandexMap(appConfig.RestaurantSettingsPreRun.YandexMapKey)
    await initializeYandexMap()
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-map-container {
  height: 400px;
}

.v-map-container > ymaps {
  box-shadow: variables.$CardShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}
</style>
